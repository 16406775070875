import React from "react";
import Heading from "../components/heading";
import DarkArrow from "../components/dark-arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';

function CourseContent() {
    return <div className="bg-light-gray pa3 pb5 pa4-m pa5-l min-vh-100-l flex flex-column z-100 relative" id="content" style={{
        boxShadow: "0 0px 60px 0 rgba(0, 0, 0, 0.6)"
    }}>
        <div className="mw9">
            <div>
                <div>
                    <Heading no="01" title="What you'll learn" description="Javacript course curriculum" />
                </div>
            </div>
            <div className="mv5">
                {curriculum.map(section => (
                    <div className="mb5">
                        <div className="flex items-center mb4">
                            <div className="mr3">
                                <img src={ `/images/${section.type}-icon.png` } />
                            </div>
                            <div>
                                <h3 className="dib f4 f4-l o-70">{ section.name }</h3>
                            </div>
                        </div>
                        <div className="flex-l">
                            {section.lessons.map(lessons => (
                                <div class="w-third-l">
                                    {lessons.map(lesson => (
                                        <div className="pa3">
                                            <img src="/images/green-mark.png" />
                                            <span class="o-40 pl3">{lesson}</span>
                                        </div>
                                    ))}
                                </div>                            
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className="center mt4 mt0-l">
            <DarkArrow onClick={() => scrollTo('#pricing')} />
        </div>
    </div>
}

export default CourseContent;

const curriculum = [
    {
        type: 'js',
        name: 'Javascript Foundation',
        lessons: [
            [
                "Javascript engine",
                "Interpreter & compiler",
                "Call stack and heap",
                "Garbgage collection",
                "Memory & threads",
                "Javascript runtime",
            ],
            [
                "Execution contexts",
                "Lexical environment",
                "Hoisting",
                "Functions",
                "Scope chains",
                "Function vs block scope",
                "This keyword",
            ],
            [
                "JS Types",
                "Pass by value vs by reference",
                "Arrays",
                "Objects",
                "Types coerce",
            ],
        ],
    },
    {
        type: 'js',
        name: 'Advanced Javascript Concepts',
        lessons: [
            [
                "Functions & objects",
                "Higher order functions",
                "Prototypes",
                "Closures",
                "OOP vs FP",
                "ES6 Classes",
            ],
            [
                "Inheritance",
                "Polymorphism",
                "Pure functions",
                "Immutability",
                "Currying",
                "Memoization",
            ],
            [
                "Single threaded JS",
                "Promises",
                "Async/await",
                "Job queue",
                "Threads and concurency",
                "CommonJS & Modules",
                "Error handling",
            ],
        ],
    },
    {
        type: 'react',
        name: 'React',
        lessons: [
            [
                "Declarative vs imperative",
                "Components",
                "Data flow",
                "Create react app",
                "JSX",
                "State and props",
                "Events",
                "Binding",
            ],
            [
                "Routing",
                "Forms",
                "Styles",
                "Higher order components",
                "Fetching data",
                "React DOM",
                "Lifecycle",
                "Deployment",
            ],
            [
                "React hooks",
                "Code splitting",
                "Error boundaries",
                "Pure components",
                "Performance",
                "Testing",
                "Webpack",
                "Babel",
            ],
        ],
    },
];