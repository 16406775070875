import React from "react";
import Logo from "./logo";
import { Link } from "gatsby";
import scrollTo from 'gatsby-plugin-smoothscroll';

function CourseNavbar() {
    return <div className="mw9 center flex-l f6">
        <Link to="/" className="tc tl-l">
            <Logo />
        </Link>
        <div className="flex-grow-1"></div>
        <div>
            <ul className="ttu list fw7">
                <li className="dn dib-l ph3 white-50 pointer dim" onClick={() => scrollTo('#content')}>What you'll learn</li>
                <li className="dn dib-l ph3 white-50 pointer dim" onClick={() => scrollTo('#pricing')}>Course pricing</li>
                <li className="dn dib-l ph3 white-50 pointer dim" onClick={() => scrollTo('#contact')}>
                    <span className="white-90 link ba b--purple bw1 pv2 ph4 br-pill">Who we are</span>
                </li>
            </ul>
        </div>
    </div>
}

export default CourseNavbar;