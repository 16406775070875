import React from "react";
import Heading from "../components/heading";
import Arrow from "../components/arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';
import Card from "../components/card";
import MailIcon from "../images/mail.svg";
import HeroImage from "../components/hero-image";
import { Link } from "gatsby";

function CourseContact() {
    return <div className="pa3 pb5 pa4-m pa5-l min-vh-100-l flex flex-column z-100 relative" id="contact">
        <div>
            <div class="mw9 center">
                <Heading no="03" title="Who we are" description="Check our projects, services and references" variant="dark" />
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="pa3 pa4-l tc">
            <div class="mw9 center flex-l items-start pt5-l">
                <div className="center mw6 w-100-l mb6 mb0-l pa3-l pr3-l">
                    <Card>
                        <article className="lh-copy">
                            <p className="white-70 fw7 f6 f5-l mb3">
                                <p>Framecoders is a team of passionate JS developers with years of experience.</p>
                                <p>We are running our own software house and launch large projects every now and then.</p>
                            </p>
                            <Link to="/" className="no-underline">
                                <div className="mt5 ph4 pt3 pb3 bg-gradient-gray-white black-80 fw7 f7 f5-l flex br2 ba b--white bw1 align-center justify-center no-underline" style={{
                                    boxShadow: "0 3px 7px 0 rgba(0,0,0, 0.5)",
                                    maxWidth: "380px",
                                    backgroundColor: "#dfdfdf"
                                }}>
                                    <div class="flex-grow-1 tc ph3">
                                        framecoders.com
                                    </div>
                                </div>
                            </Link>
                        </article>
                    </Card>
                </div>
                <div className="w-100-l tc relative-l pt3-l">
                    <div className="absolute-l top-3 tc w-100">
                        <h1 className="white f3 f3-m f2-l pb0 mb2 mb0-ns">FrameCoders</h1>
                        <h2 className="f6 f5-l lh-copy ttu light-purple pt1 mt0">
                            <span>We dream in code &amp; code the dreams</span>
                        </h2>
                    </div>
                    <HeroImage />
                </div>
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="center">
            <Arrow onClick={() => scrollTo('#course-hero')} className="rotate-180" />
        </div>
    </div>
}

export default CourseContact;