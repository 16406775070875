import React from "react";
import Heading from "../components/heading";
import Arrow from "../components/arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';
import Card from "../components/card";

function CoursePricing() {
    return <div className="pa3 pb5 pa4-m pa5-l min-vh-100-l flex flex-column z-10" id="pricing">
        <div>
            <div class="mw9 center">
                <Heading no="02" title="Course pricing" description="Low price, high quality" variant="white" />
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="pa3 pa5-l tc">
            <div className="flex-l">
                <div className="tl white lh-copy w-40-l mb6">
                    <h3 className="f4 f3-l mb0 pb2">100% REMOTE ON-LINE CLASSES</h3>
                    <h4 className="fw3 o-60 mt2 pl3">
                        We will meet on-line using interactive software to help you get better results fast
                    </h4>
                    <h3 className="f4 f3-l mt5 mb0 pb2">SATISFACTION GUARANTEE</h3>
                    <h4 className="fw3 o-60 mt2 pl3">
                        If the course doesn't meet your requirements after the first class - you can get the refund
                    </h4>
                </div>
                <div className="mw6 center w-100-l pl0-l mb4">
                    <Card shadow={false} background={"bg-pricing-card"}>
                        <article className="lh-copy">
                            <h3 className="fw7 f4 f2-l pa0 mt0 mb3">4960 PLN</h3>
                            <div className="bg-white mb5" style={{
                                width: "50px",
                                height: "1px",
                            }}></div>
                            <p className="white-70 fw7 f6 f5-l mb3">
                                <p>- 6 weeks long bootcamp (weekends)</p>
                                <p>- Practical projects and engaging homeworks</p>
                                <p>- Job offers for best candidates</p>
                            </p>
                            <a href="mailto:office@framecoders.com" className="no-underline">
                                <div className="w-100 mt5 ph4 pt3 pb3 bg-gradient-gray-white black-80 fw7 f7 f5-l db br2 ba b--white bw1 align-center justify-center no-underline" style={{
                                    boxShadow: "0 3px 7px 0 rgba(0,0,0, 0.5)",
                                    maxWidth: "380px",
                                    backgroundColor: "#dfdfdf"
                                }}>
                                    <div class="flex-grow-1 tc ph3">
                                        SIGN UP FOR THE COURSE NOW
                                    </div>
                                </div>
                            </a>
                        </article>
                    </Card>
                </div>
            </div>
        </div>
        <div className="flex-grow-1"></div>
        <div className="center">
            <Arrow onClick={() => scrollTo('#contact')} />
        </div>
    </div>
}

export default CoursePricing;