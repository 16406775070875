import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import CourseHero from "../sections/course-hero";
import CourseContent from "../sections/course-content";
import CoursePricing from "../sections/course-pricing";
import CourseContact from "../sections/course-contact";
import Footer from "../sections/footer";

function Js() {
    return  <Layout>
      <SEO title="The ultimate Javascript bootcamp with React" />
      <CourseHero />
      <CourseContent />
      <CoursePricing />
      <CourseContact />
      <Footer />
    </Layout>
}

export default Js;