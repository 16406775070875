import React from "react";
import CourseNavbar from "../components/course-navbar";
import Arrow from "../components/arrow";
import scrollTo from 'gatsby-plugin-smoothscroll';

function CourseHero() {
    return <div className="pa4 pa5-l white min-vh-100-l flex flex-column" id="course-hero">
        <div>
            <CourseNavbar />
        </div>
        <div className="flex-grow-1"></div>
        <div className="ph5-l pt5 pt4-l pb5 tl flex flex-column align-center">
            <div className="flex-grow-1"></div>
            <div className="overflow-hidden flex-l">
                <div className="pa4-l">
                    <img src="/images/js-big.png" />
                </div>
                <div className="flex-grow-1">
                    <h1 className="white f3 f2-m f1-l pb0 mb3 mb2-ns">The ultimate Javascript bootcamp</h1>
                    <h2 className="f6 f4-l lh-copy ttu light-purple pt1 mt0">
                        <span>Everything you need to know to master JS</span>
                    </h2>
                </div>
            </div>
            <div className="flex-grow-1"></div>
        </div>
        <div className="center overflow-hidden">
            <Arrow onClick={() => scrollTo('#content')} />
        </div>
    </div>
}

export default CourseHero;